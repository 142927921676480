import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dataset, DatasetEntry } from "../models/dataset";

export interface DatasetState {
  dataset: Dataset;
}

const initialState: DatasetState = {
  dataset: { DatasetEntries: [] },
};

const datasetSlice = createSlice({
  name: "dataset",
  initialState,
  reducers: {
    setDataset(state, action: PayloadAction<Dataset>) {
      state.dataset = action.payload;
    },
    addToOrModifyDataset(state, action: PayloadAction<DatasetEntry>) {
      const entry = state.dataset.DatasetEntries.find((x) => x.ProductQpdId);
      if (entry) {
        state.dataset.DatasetEntries[state.dataset.DatasetEntries.findIndex((x) => x.ProductQpdId)] = action.payload;
        return;
      }
      state.dataset.DatasetEntries.push(action.payload);
    },
  },
});

export const { setDataset, addToOrModifyDataset } = datasetSlice.actions;

export default datasetSlice.reducer;
