import { useEffect, useState } from "react";
import { AppBar, AppBarControls, Button, LanguageMenu, MenuItem, UserMenu } from "@magicad-cloud/component-library";
import { LibraryBooks, Mail, Poll, TrendingUp } from "@mui/icons-material";
import { useAppSelector, useSigninRedirect, useSignoutRedirect } from "../../hooks";
import { useAuth } from "react-oidc-context";
import { config } from "../../config/config";
import { getBasePath } from "../../helpers/urlHelpers";

interface AppBarContainerProps {
  handleProductRequestOpen: () => void;
}

export const AppBarContainer = ({ handleProductRequestOpen }: AppBarContainerProps) => {
  const manufacturer = useAppSelector((state) => state.manufacturer);
  const handleLogin = useSigninRedirect();
  const handleLogout = useSignoutRedirect();

  const auth = useAuth();

  const [user, setUser] = useState<{
    name: string;
    email: string;
  } | null>(
    auth.user
      ? {
          name: `${auth.user.profile.firstname as string} ${auth.user.profile.lastname as string}`,
          email: auth.user.profile.email as string,
        }
      : null
  );

  useEffect(() => {
    if (auth.user && auth.isAuthenticated) {
      setUser({
        name: `${auth.user.profile.firstname as string} ${auth.user.profile.lastname as string}`,
        email: auth.user.profile.email as string,
      });
    } else {
      setUser(null);
    }
  }, [auth]);

  const href = `${getBasePath()}/`;

  return (
    <AppBar
      branded
      brandLogoUrl={manufacturer.LogoUrl}
      poweredByLogoUrl={config.magiCadCloudPoweredByLogoUrl}
      className="AppBar"
      href={href}
    >
      <AppBarControls>
        <Button href="https://www.magicad.com/en/magicad-cloud-faq/" label="FAQ" />
        <Button href="https://www.magicad.com/en" label="MagiCAD" />
        <Button
          color="neutral"
          label="UI.Request.Button"
          size="medium"
          variant="text"
          onClick={handleProductRequestOpen}
        />
        <LanguageMenu languages={config.supportedLanguages} />
        <UserMenu user={user} onLogin={handleLogin} onLogout={handleLogout}>
          <MenuItem
            label="UI.Login.ProductSets"
            icon={<LibraryBooks fontSize="small" />}
            href={config.collectionsUrl}
          />
          <MenuItem label="UI.Login.Mollier" icon={<TrendingUp fontSize="small" />} href={config.mollierUrl} />
          <MenuItem label="UI.Request.Button" icon={<Mail fontSize="small" />} onClick={handleProductRequestOpen} />
          <MenuItem
            label="UI.Login.ManufacturerHub"
            icon={<Poll fontSize="small" />}
            href={config.manufacturerHubUrl}
          />
        </UserMenu>
      </AppBarControls>
    </AppBar>
  );
};
