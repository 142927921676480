import {
  AggregateFilter,
  AttachmentOption,
  ProductClass,
  OperativeFilter,
  SortOption,
} from "@magicad-cloud/component-library";
import { intersection, keys } from "lodash";
import { SupportedLocale } from "../common/types";
import { CommonTelemetryProperties } from "../telemetry-provider-react/types";
import { Application } from "./application";

export interface SearchRequest {
  AggregateFilters: AggregateFilter[];
  AggregateMinimumDocumentCount: number;
  Applications?: Application[];
  AttachmentTypes: AttachmentOption[];
  From: number;
  IsBrandedPage: true;
  LanguageLocale: SupportedLocale;
  Limits: { ProductClasses: ProductClass[] };
  ManufacturerGroups: [];
  Manufacturers: string[];
  MarketAreas: [] | [string];
  OperativeFilters: OperativeFilter[];
  ProductClasses: number[];
  ProductGroups?: number[];
  Size: number;
  SortBy: SortOption;
  Text: string;
  ProductSetId: string | null;
}

export const defaultSearchRequest: SearchRequest = {
  AggregateFilters: [],
  AggregateMinimumDocumentCount: 1,
  AttachmentTypes: [],
  From: 0,
  IsBrandedPage: true,
  LanguageLocale: "en-GB",
  Limits: { ProductClasses: [] },
  ManufacturerGroups: [],
  Manufacturers: [],
  MarketAreas: [],
  OperativeFilters: [],
  ProductClasses: [],
  ProductGroups: [],
  Size: 50,
  SortBy: "SearchScore",
  Text: "",
  ProductSetId: null,
};

export type SearchRequestWithTelemetryProperties = SearchRequest & CommonTelemetryProperties;

export const searchRequestKeys = keys(defaultSearchRequest);

// Functions below are not in use, may become handy later

export const isSearchRequest = (data: unknown): data is SearchRequest => {
  if (!data || typeof data !== "object") throw new Error();

  const searchRequestKeys = keys(defaultSearchRequest);
  const dataKeys = keys(data);
  const keysInBoth = intersection(searchRequestKeys, dataKeys);
  return keysInBoth.length > 0;
};

export const hasSearchRequestParams = (searchRequestParams: Partial<SearchRequest> | undefined) => {
  const searchRequestKeys = keys(defaultSearchRequest);
  const dataKeys = keys(searchRequestParams);
  const keysInBoth = intersection(searchRequestKeys, dataKeys);
  return keysInBoth.length > 0;
};
