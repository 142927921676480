import { useEffect } from "react";
import {
  FacetDrawer,
  GenericAggregate,
  isGenericAggregate,
  GenericRangeAggregate,
  AggregateFilter,
  ProductGroupsFacet,
  ProductGroupFilter,
  createAggregateFilterChip,
} from "@magicad-cloud/component-library";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { addChip, clearAllFacetChips, removeChipById } from "../../../slices/chipsSlice";
import { partition } from "lodash";
import { useGetSearchOptionsQueryState, useGetSearchResultQueryState } from "../../../api/searchApi";
import { BimFacetGroup } from "./bimFacets/BimFacetGroup";
import { GenericFacetGroup } from "./genericFacets/GenericFacetGroup";
import { GenericRangeFacetGroup } from "./genericRangeFacets/GenericRangeFacetGroup";
import { FacetSkeletons } from "./facetSkeletons/FacetSkeletons";
import {
  addAggregateFilter as addAggregateFilterToSearchOptionsRequest,
  clearFacetFilters as clearSearchOptionsRequestFacetFilters,
  removeAggregateFilter as removeAggregateFilterFromSearchOptionsRequest,
} from "../../../slices/searchOptionsRequestSlice";
import {
  addAggregateFilter as addAggregateFilterToSearchRequest,
  addProductGroups,
  clearFacetFilters as clearSearchRequestFacetFilters,
  removeAggregateFilter as removeAggregateFilterFromSearchRequest,
  removeProductGroups,
} from "../../../slices/searchRequestSlice";
import hash from "object-hash";
import { useTelemetry } from "../../../telemetry-provider-react/telemetryHooks";

export interface FacetDrawerControls {
  facetDrawerOpen: boolean;
  productGroupsExpanded: boolean;
  handleFacetDrawerOpen: () => void;
  handleFacetDrawerClose: () => void;
  handleProductGroupsExpand: (expanded: boolean) => void;
}

interface FacetFiltersProps {
  facetDrawerOpen: boolean;
  productGroupsExpanded: boolean;
  handleFacetDrawerOpen: () => void;
  handleFacetDrawerClose: () => void;
  handleProductGroupsExpand: (expanded: boolean) => void;
}

export const FacetFilters = ({
  facetDrawerOpen,
  productGroupsExpanded,
  handleProductGroupsExpand,
  handleFacetDrawerOpen,
  handleFacetDrawerClose,
}: FacetFiltersProps) => {
  const dispatch = useAppDispatch();
  const { commonTelemetryProperties } = useTelemetry();

  const { searchOptionsRequest } = useAppSelector((state) => state.searchOptionsRequest);
  const { searchRequest } = useAppSelector((state) => state.searchRequest);

  const productGroupFilters = useAppSelector((state) => state.searchRequest.searchRequest.ProductGroups) ?? [];

  const { data: searchOptions, isFetching: searchOptionsFetching } =
    useGetSearchOptionsQueryState(searchOptionsRequest);

  const searchRequestWithTelemetryProperties = { ...searchRequest, ...commonTelemetryProperties };

  const {
    data: searchResults = {
      Aggregates: {
        Generic: [] as GenericAggregate[],
        BimObjectProperties: [] as GenericAggregate[],
      },
      Total: 0,
    },
    isFetching: searchResultsFetching,
  } = useGetSearchResultQueryState(searchRequestWithTelemetryProperties);

  const handleProductGroupsFilterAdd = (productGroupFilters: ProductGroupFilter[]) => {
    const ids = productGroupFilters.map((p) => p.value);
    dispatch(addProductGroups(ids));
  };

  const handleProductGroupsFilterRemove = (productGroupFilters: ProductGroupFilter[]) => {
    const ids = productGroupFilters.map((p) => p.value);
    dispatch(removeProductGroups(ids));
  };

  const generic = searchResults.Aggregates?.Generic ? searchResults.Aggregates.Generic : ([] as GenericAggregate[]);
  const bimAggregates = searchResults.Aggregates?.BimObjectProperties
    ? searchResults.Aggregates.BimObjectProperties
    : ([] as GenericAggregate[]);

  const totalProducts = searchResults.Total;

  useEffect(() => {
    if (totalProducts === 0) {
      handleFacetDrawerClose();
    }
  }, [handleFacetDrawerClose, totalProducts]);

  const [genericAggregates, genericRangeAggregates]: [GenericAggregate[], GenericRangeAggregate[]] = partition(
    generic,
    isGenericAggregate
  );

  const handleAggregateFilterAdd = (aggregateFilter: AggregateFilter) => {
    dispatch(addChip(createAggregateFilterChip(aggregateFilter)));
    dispatch(addAggregateFilterToSearchOptionsRequest(aggregateFilter));
    dispatch(addAggregateFilterToSearchRequest(aggregateFilter));
  };

  const handleAggregateFilterRemove = (aggregateFilter: AggregateFilter) => {
    dispatch(removeChipById(hash(aggregateFilter)));
    dispatch(removeAggregateFilterFromSearchOptionsRequest(aggregateFilter));
    dispatch(removeAggregateFilterFromSearchRequest(aggregateFilter));
  };

  const handleFacetFiltersClear = () => {
    dispatch(clearAllFacetChips());
    dispatch(clearSearchOptionsRequestFacetFilters());
    dispatch(clearSearchRequestFacetFilters());
  };

  const handleProductGroupsFacetChange = (event: React.SyntheticEvent, isExpanded: boolean) =>
    handleProductGroupsExpand(isExpanded);

  return (
    <FacetDrawer
      open={facetDrawerOpen}
      disabled={searchOptionsFetching || searchResultsFetching || totalProducts === 0}
      handleOpen={handleFacetDrawerOpen}
      handleClose={handleFacetDrawerClose}
      clearAllFacetFilters={handleFacetFiltersClear}
    >
      {searchOptionsFetching || searchResultsFetching ? (
        <FacetSkeletons amount={1} />
      ) : (
        <ProductGroupsFacet
          productGroups={searchOptions?.ProductGroups ?? []}
          selectedProductGroupIds={productGroupFilters}
          expanded={productGroupsExpanded}
          onChange={handleProductGroupsFacetChange}
          addProductGroupFilters={handleProductGroupsFilterAdd}
          removeProductGroupFilters={handleProductGroupsFilterRemove}
        />
      )}
      <BimFacetGroup
        aggregates={bimAggregates}
        loading={searchResultsFetching}
        addAggregateFilter={handleAggregateFilterAdd}
        removeAggregateFilter={handleAggregateFilterRemove}
      />
      <GenericFacetGroup
        aggregates={genericAggregates}
        loading={searchResultsFetching}
        addAggregateFilter={handleAggregateFilterAdd}
        removeAggregateFilter={handleAggregateFilterRemove}
      />
      <GenericRangeFacetGroup
        aggregates={genericRangeAggregates}
        loading={searchResultsFetching}
        addAggregateFilter={handleAggregateFilterAdd}
        removeAggregateFilter={handleAggregateFilterRemove}
      />
    </FacetDrawer>
  );
};
