import { ReactNode } from "react";
import { Divider } from "@mui/material";
import "./MainLayout.scss";

export interface MainLayoutProps {
  linearProgress: ReactNode;
  newVersionAlert: ReactNode;
  appBar: ReactNode;
  banner: ReactNode;
  searchBar: ReactNode;
  searchFilters: ReactNode;
  filterControls: ReactNode;
  chips: ReactNode;
  collectionInfo?: ReactNode;
  resultInfo: ReactNode;
  topPagination: ReactNode;
  productCards: ReactNode;
  bottomPagination: ReactNode;
  footer: ReactNode;
}

export const MainLayout = ({
  linearProgress,
  newVersionAlert,
  appBar,
  banner,
  searchBar,
  searchFilters,
  filterControls,
  chips,
  collectionInfo,
  resultInfo,
  topPagination,
  productCards,
  bottomPagination,
  footer,
}: MainLayoutProps) => {
  return (
    <div className="MainLayout">
      <div className="MainLayout__feedback">
        {linearProgress}
        {newVersionAlert}
      </div>
      <div className="MainLayout__top">
        <div className="MainLayout__appbar">{appBar}</div>
        <div className="MainLayout__banner">{banner}</div>
        <div className="MainLayout__search">
          <div className="MainLayout__searchbar">{searchBar}</div>
          <div className="MainLayout__search-filters">{searchFilters}</div>
        </div>
        <div className="MainLayout__filter-controls">{filterControls}</div>
        <div className="MainLayout__divider">
          <Divider />
        </div>
      </div>
      <div className="MainLayout__bottom">
        {collectionInfo && <div className="MainLayout__collection-info">{collectionInfo}</div>}
        <div className="MainLayout__divider_collection">{collectionInfo && <Divider />}</div>
        <div className="MainLayout__chips">{chips}</div>
        <div className="MainLayout__result-info">{resultInfo}</div>
        <div className="MainLayout__top-pagination">{topPagination}</div>
        <div className="MainLayout__product-cards">{productCards}</div>
        <div className="MainLayout__bottom-pagination">{bottomPagination}</div>
      </div>
      <div className="MainLayout__footer">{footer}</div>
    </div>
  );
};
