import { useEffect, useMemo } from "react";
import { decodeBase64, encodeBase64, isValidBase64 } from "../utils/base64Utils";
import { get, isEmpty, isEqual, keys, omit, set, setWith, unset } from "lodash";
import { useAppDispatch, useAppSelector } from "./reduxHooks";
import { applyBase64ParamsToSearchRequest } from "../slices/searchRequestSlice";
import { useTranslation } from "react-i18next";
import {
  createAggregateFilterChip,
  createOperativeFilterChip,
  createAttachmentChip,
  createTextFilterChip,
} from "@magicad-cloud/component-library";
import { initMeasurementUnits } from "../slices/measurementUnitsSlice";
import { useParams } from "react-router-dom";
import { addChip, chipsSelector } from "../slices/chipsSlice";
import { store } from "../app/store";
import { applyBase64ParamsToSearchOptionsRequest } from "../slices/searchOptionsRequestSlice";
import { initApplicationParams } from "../slices/applicationParamsSlice";
import { defaultSearchRequest } from "../models/searchRequest";
import { defaultMagiCadParams, defaultPlainRevitParams, isConnectParams } from "../models/connectParams";
import { setConnectParams } from "../slices/connectParamsSlice";
import { Base64Params } from "../models/base64Params";
import { setOpenedProduct, setOpenedVariant } from "../slices/openedProductSlice";
import { replaceSearchPath } from "../helpers/urlHelpers";

export function useBase64ParamsReader() {
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();
  const { encodedParams: base64encoded } = useParams();

  useEffect(() => {
    const base64Params = getBase64Params(base64encoded);

    const { searchParams, openedProductParams, chipsParams, ...connectParams } = base64Params ?? {};

    const validConnectParams = isConnectParams(connectParams);

    if (validConnectParams) {
      dispatch(setConnectParams(connectParams));
      dispatch(initApplicationParams(connectParams));
      dispatch(initMeasurementUnits(connectParams));
    }

    dispatch(
      applyBase64ParamsToSearchOptionsRequest({
        connectParams: validConnectParams ? connectParams : undefined,
        searchParams,
      })
    );

    dispatch(
      applyBase64ParamsToSearchRequest({ connectParams: validConnectParams ? connectParams : undefined, searchParams })
    );

    if (searchParams?.LanguageLocale) {
      const language = searchParams.LanguageLocale.split("-")[0];
      if (i18n.language !== language) void i18n.changeLanguage(language);
    }

    searchParams?.AggregateFilters?.forEach((filter) => dispatch(addChip(createAggregateFilterChip(filter))));
    searchParams?.OperativeFilters?.forEach((filter) => dispatch(addChip(createOperativeFilterChip(filter))));

    if (searchParams?.AttachmentTypes?.includes("RFA")) {
      dispatch(addChip(createAttachmentChip("Revit", "RFA")));
    }

    if (searchParams?.AttachmentTypes?.includes("DXF")) {
      dispatch(addChip(createAttachmentChip("AutoCAD", "DXF")));
    }

    if (!isEmpty(searchParams?.Text)) {
      searchParams?.Text?.split(" ").map((t) => dispatch(addChip(createTextFilterChip(t))));
    }

    if (openedProductParams) {
      dispatch(setOpenedProduct(openedProductParams.productId));
      dispatch(setOpenedVariant(openedProductParams.variantId));
    }

    if (chipsParams) {
      chipsParams.forEach((c) => dispatch(addChip(c)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, i18n]);
}

export function useBase64ParamsWriter() {
  const { manufacturerNameOrId: manufacturerNameOrIdParam, encodedParams: base64 } = useParams();
  const connectParams = useAppSelector((store) => store.connectParams);
  const { searchRequest: storedSearchRequest, base64ParamsApplied } = useAppSelector((store) => store.searchRequest);
  const productClassChips = chipsSelector.selectProductClassChips(store.getState().chips);
  const openedProduct = useAppSelector((store) => store.openedProduct);

  const newBase64 = useMemo(() => {
    if (!base64ParamsApplied) return null;

    if (connectParams && !storedSearchRequest) {
      return base64 === "" ? null : "";
    }

    const base64Params: Base64Params = { ...connectParams };

    if (storedSearchRequest) {
      keys(storedSearchRequest).forEach((key) => {
        const storedValue: unknown = get(storedSearchRequest, key);
        const defaultValue: unknown = get(defaultSearchRequest, key);

        if (!isEqual(storedValue, defaultValue)) setWith(base64Params, `[searchParams][${key}]`, storedValue);
      });
    }

    productClassChips.length > 0
      ? set(base64Params, "chipsParams", productClassChips)
      : unset(base64Params, "chipsParams");

    openedProduct.productId
      ? set(base64Params, "openedProductParams", openedProduct)
      : unset(base64Params, "openedProductParams");

    const encoded = !isEmpty(base64Params) ? encodeBase64(JSON.stringify(base64Params)) : "";

    return encoded !== base64 ? encoded : null;
  }, [base64ParamsApplied, connectParams, storedSearchRequest, productClassChips, openedProduct, base64]);

  useEffect(() => {
    if (newBase64) {
      replaceSearchPath(newBase64, manufacturerNameOrIdParam);
    }
  }, [manufacturerNameOrIdParam, newBase64]);
}

const getBase64Params = (base64?: string): Base64Params | null => {
  if (base64 && base64 === "magicad") {
    return defaultMagiCadParams;
  }
  if (base64 && base64 === "plainrevit") {
    return defaultPlainRevitParams;
  }
  if (base64 && isValidBase64(base64)) {
    try {
      const base64Params = JSON.parse(decodeBase64(base64)) as Base64Params;

      return omit(base64Params, ["searchParams.Manufacturers"]);
    } catch (e) {
      //nop
    }
  }
  return null;
};
