import { SupportedLocale, SupportedLanguage } from "../common/types";

const supportedLanguages = ["en", "de", "fr", "ru", "zh", "es", "it"] as SupportedLanguage[];

const languageLocales = {
  en: "en-GB",
  de: "de-DE",
  fr: "fr-FR",
  ru: "ru-RU",
  zh: "zh-CN",
  es: "es-ES",
  it: "it-IT",
} as Record<SupportedLanguage, SupportedLocale>;

type Config = {
  searchApiUrl: string;
  collectionsApiUrl: string;
  userApiUrl: string;
  translationsUrl: string;
  blobUrl: string;
  magiCadCloudLogoUrl: string;
  magiCadCloudPoweredByLogoUrl: string;
  brandedIllustrationUrl: string;
  collectionsUrl: string;
  mollierUrl: string;
  manufacturerHubUrl: string;
  productDataApiUrl: string;
  supportedLanguages: SupportedLanguage[];
  languageLocales: Record<SupportedLanguage, SupportedLocale>;
  configurationUrl: string;
  redirectUrl: string;
  connectInsertRedirectUrl: string;
  connectAllowReinsertVersion: Date;
  subscriptionApiUrl: string;
  environment?: "local" | "development" | "qa" | "production";
  versionCheckInterval: number;
  releaseNumber: string;
  signupUrl: string;
};

const searchApiUrl = process.env.REACT_APP_SEARCH_API_URL;
const collectionsApiUrl = process.env.REACT_APP_COLLECTIONS_API_URL;
const userApiUrl = process.env.REACT_APP_USER_API_URL;
const translationsUrl = process.env.REACT_APP_TRANSLATIONS_URL;
const blobUrl = process.env.REACT_APP_BLOB_URL;

const magiCadCloudLogoUrl = process.env.REACT_APP_MAGICAD_CLOUD_LOGO_URL;
const magiCadCloudPoweredByLogoUrl = process.env.REACT_APP_MAGICAD_CLOUD_POWERED_BY_LOGO_URL;
const brandedIllustrationUrl = process.env.REACT_APP_MAGICAD_CLOUD_BRANDED_ILLUSTRATION_URL;
const collectionsUrl = process.env.REACT_APP_COLLECTIONS_URL;
const mollierUrl = process.env.REACT_APP_MOLLIER_URL;
const manufacturerHubUrl = process.env.REACT_APP_MANUFACTURER_HUB_URL;
const productDataApiUrl = process.env.REACT_APP_PRODUCT_DATA_API_URL;
const configurationUrl = process.env.REACT_APP_CONFIGURATION_API_URL;
const subscriptionApiUrl = process.env.REACT_APP_SUBSCRIPTION_API_URL;

const redirectUrl = process.env.REACT_APP_REDIRECT_URL;
const connectInsertRedirectUrl = process.env.REACT_APP_CONNECT_INSERT_REDIRECT_URL;
const releaseNumber = process.env.REACT_APP_RELEASENUMBER ?? "";

const signupUrl = process.env.REACT_APP_SIGNUP_URL;

const parseEnvironmentValue = (name: string, value: string | undefined) => {
  if (value !== "local" && value !== "development" && value !== "qa" && value !== "production" && value !== undefined) {
    throw new Error(`${name} invalid`);
  }

  return value;
};

const environment = parseEnvironmentValue("REACT_APP_ENVIRONMENT", process.env.REACT_APP_ENVIRONMENT);

if (!process.env.REACT_APP_CONNECT_ALLOW_REINSERT_VERSION)
  throw new Error("REACT_APP_CONNECT_ALLOW_REINSERT_VERSION not defined");
const connectAllowReinsertVersion = new Date(process.env.REACT_APP_CONNECT_ALLOW_REINSERT_VERSION);

if (!process.env.REACT_APP_VERSION_CHECK_INTERVAL) throw new Error("REACT_APP_VERSION_CHECK_INTERVAL not defined");
const versionCheckInterval = parseInt(process.env.REACT_APP_VERSION_CHECK_INTERVAL);

if (!searchApiUrl) throw new Error("REACT_APP_SEARCH_API_URL not defined");
if (!collectionsApiUrl) throw new Error("REACT_APP_COLLECTIONS_API_URL not defined");
if (!userApiUrl) throw new Error("REACT_APP_USER_API_URL not defined");
if (!translationsUrl) throw new Error("REACT_APP_TRANSLATIONS_URL not defined");
if (!blobUrl) throw new Error("REACT_APP_BLOB_URL not defined");
if (!magiCadCloudLogoUrl) throw new Error("REACT_APP_MAGICAD_CLOUD_LOGO_URL not defined");
if (!magiCadCloudPoweredByLogoUrl) throw new Error("REACT_APP_MAGICAD_CLOUD_POWERED_BY_LOGO_URL not defined");
if (!brandedIllustrationUrl) throw new Error("REACT_APP_MAGICAD_CLOUD_BRANDED_ILLUSTRATION_URL not defined");
if (!collectionsUrl) throw new Error("REACT_APP_COLLECTIONS_URL not defined");
if (!mollierUrl) throw new Error("REACT_APP_MOLLIER_URL not defined");
if (!manufacturerHubUrl) throw new Error("REACT_APP_MANUFACTURER_HUB_URL not defined");
if (!productDataApiUrl) throw new Error("REACT_APP_PRODUCT_DATA_API_URL not defined");
if (!configurationUrl) throw new Error("REACT_APP_CONFIGURATION_API_URL not defined");
if (!redirectUrl) throw new Error("REACT_APP_REDIRECT_URL not defined");
if (!connectInsertRedirectUrl) throw new Error("REACT_APP_CONNECT_INSERT_REDIRECT_URL not defined");
if (!connectAllowReinsertVersion) throw new Error("REACT_APP_CONNECT_ALLOW_REINSERT_VERSION not defined");
if (!subscriptionApiUrl) throw new Error("REACT_APP_SUBSCRIPTION_API_URL not defined");
if (!signupUrl) throw new Error("REACT_APP_SIGNUP_URL not defined");

export const config: Config = {
  searchApiUrl,
  collectionsApiUrl,
  userApiUrl,
  translationsUrl,
  blobUrl,
  magiCadCloudLogoUrl,
  magiCadCloudPoweredByLogoUrl,
  brandedIllustrationUrl,
  collectionsUrl,
  mollierUrl,
  manufacturerHubUrl,
  productDataApiUrl,
  supportedLanguages,
  languageLocales,
  configurationUrl,
  redirectUrl,
  connectInsertRedirectUrl,
  connectAllowReinsertVersion,
  environment,
  subscriptionApiUrl,
  versionCheckInterval,
  releaseNumber,
  signupUrl,
};
