import { useCallback, useEffect, useRef } from "react";
import { useAuth } from "react-oidc-context";

interface useAccessTokenResponse {
  fetchAccessToken: () => Promise<string>;
  fetchAccessTokenRedirect: () => Promise<string | undefined>;
}

export const useAccessToken = (): useAccessTokenResponse => {
  const auth = useAuth();

  const fetchAccessToken = useCallback(async (): Promise<string> => {
    if (auth.isAuthenticated && auth.user) return auth.user.access_token;

    const user = await auth.signinPopup();
    return user.access_token;
  }, [auth]);

  const fetchAccessTokenRedirect = useCallback(async (): Promise<string | undefined> => {
    if (auth.isAuthenticated && auth.user) return auth.user.access_token;
    await auth.signinRedirect();
  }, [auth]);

  return { fetchAccessToken, fetchAccessTokenRedirect };
};

export const useAccessTokenExpiredEventHandler = () => {
  const auth = useAuth();

  useEffect(() => {
    return auth.events.addAccessTokenExpired(() => {
      return auth.removeUser();
    });
  }, [auth]);
};

export const useSigninRedirect = () => {
  const auth = useAuth();

  const handleLogin = () => {
    localStorage.setItem("magicadcloud.branded.path", window.location.pathname);
    void auth.signinRedirect();
  };

  return handleLogin;
};

export const useForcedSigninRedirect = () => {
  const auth = useAuth();
  const loginForcedContainer = useRef(false);
  const handleLogin = useSigninRedirect();

  useEffect(() => {
    if (!loginForcedContainer.current) {
      if (!auth.isLoading) {
        loginForcedContainer.current = true;
        if (!auth.isAuthenticated) handleLogin();
      }
    }
  }, [auth, handleLogin]);
};

export const useSignoutRedirect = () => {
  const auth = useAuth();

  const handleLogout = () => {
    localStorage.setItem("magicadcloud.branded.path", window.location.pathname);
    void auth.signoutRedirect();
  };

  return handleLogout;
};
